<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>公文管理</el-breadcrumb-item>
      <el-breadcrumb-item>财务管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <!-- 按钮区域 -->
      <div class="btns" style="display:flex;align-items:center;justify-content: space-between;">
        <el-button type="primary" icon="el-icon-document-checked" class="btn_1" @click="addCommd">提现</el-button>
        <el-tooltip class="item" effect="dark" content="可用余额不包含正在提现审核的金额" placement="top-start">
          <div style="width: 200px;">
            可用余额：{{yue}}
          </div>
        </el-tooltip>
      </div>
      <!-- 商品列表 -->
      <el-table :height="'30rem'" :data="commodityList" border style="width: 100%">
        <el-table-column label="提现时间">
          <template slot-scope="scope">
            <div>
              {{scope.row.createTime}}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="describe" label="提现金额">
          <template slot-scope="scope">
            <div style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">
              {{scope.row.money}}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="200" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.states==1" size="medium" type="success">提现成功</el-tag>
            <el-tag v-else size="medium" type="warning">审核中</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination style="margin: 15px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="page" :page-sizes="[5,10,20]" :page-size="limit" layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
      <!-- 弹出层 -->
      <el-dialog @close="close" :title="currentDialog" :visible.sync="centerDialogVisible" width="50%" center>
        <div>
          <div style="height: 50vh;">
            <!-- 商品名称 -->
            <div class="dialogItem">
            </div>
            <!-- 订单内容 -->
            <div style="width: 100%;height: 70%;margin-top: 30px;font-size:18px;">
              <div>
                <div style="margin-bottom: 30px;">
                  <!-- 更换 -->
                  <div v-if="false" style="display: flex;align-items: center;margin-bottom: 25px;">
                    <div style="width: 100px;">提现账户</div>
                    <el-input disabled size="small" style="width:300px" ref="input" :placeholder="input3"
                      class="input-with-select input-placeholder">
                    </el-input>
                    <!-- <el-button style="font-size:14px;margin: 0 20px;" size="small" @click="accountState=!accountState"
                      type="warning">更换</el-button>
                    <el-button type="primary" size="small">确认</el-button> -->
                  </div>
                  <!-- 账户名称 -->
                  <div style="display: flex;align-items: center;margin-bottom: 25px;">
                    <div style="width: 100px;">账户名称</div>
                    <el-input size="small" style="width:300px" v-model="accountTitle" ref="input"
                      placeholder="请输入对公账号名称" class="input-with-select input-placeholder">
                    </el-input>
                    <!-- <el-button style="font-size:14px;margin: 0 20px;" size="small" @click="accountState=!accountState"
                      type="primary">添加</el-button>
                    <el-button type="primary" size="small">确认</el-button> -->
                  </div>
                  <!-- 支行名称 -->
                  <div style="display: flex;align-items: center;margin-bottom: 25px;">
                    <div style="width: 100px;">支行名称</div>
                    <el-input size="small" style="width:300px" v-model="subBranch" ref="input" placeholder="请输入支行名称"
                      class="input-with-select input-placeholder">
                    </el-input>
                  </div>
                  <!-- 提现账户 -->
                  <div style="display: flex;align-items: center;">
                    <div style="width: 100px;">提现账户</div>
                    <el-input size="small" style="width:300px" v-model="input3" ref="input" placeholder="请输入对公账号"
                      class="input-with-select input-placeholder">
                    </el-input>
                  </div>
                  <!-- 提示 -->
                  <div style="color:red;font-size:14px;margin:5px 0 0 100px;">
                    *将提现到该账户，请认真核对
                  </div>
                </div>
                <!-- 提现金额 -->
                <div style="margin-bottom: 30px;">
                  <div style="display: flex;align-items: center;">
                    <div style="width: 100px;">提现金额：</div>
                    <el-input size="small" style="width:300px" placeholder="请输入提现金额" v-model="money"
                      class="input-with-select">
                    </el-input>
                  </div>
                  <!-- 提示 -->
                  <div style="color:red;font-size:14px;margin:5px 0 0 100px;">
                    *提现余额不会大于剩余余额
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="display:flex;justify-content:space-around">
            <el-button @click="centerDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitMap">确 定</el-button>
          </div>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        accountState: false,// 对公账户开关
        input3: '',// 账号
        money: '',// 提现金额
        accountTitle: '',// 账户名称
        subBranch: '',// 支行名称
        yue: 0,// 余额
        commodityList: [],
        page: 1,
        limit: 5,// 每页显示
        total: 0,//总条数
        optionsList: [
          {
            name: '',
            num: '',
            per: '',
          }
        ],// 项目列表展示
        currentDialog: '',//弹框状态
        centerDialogVisible: false,// 弹框开关
        imgurl: 'https://www.idemer.com/web/upload/file',
        // imgurl: 'http://192.168.3.165:8069/upload/file',
        fileList: [],// 商品图片文件列表
        fileList2: [],// 图文详情文件列表
        textarea: '',// 商品描述
        comName: '',//商品名称
        price: '',// 商品价格
        currentItem: '',// 当前编辑项
        item: '',// 当前查询订单
        options: [{
          value: '1',
          label: '全部'
        }],
        value: '1',// 筛选条件
      }
    },
    created() {
      this.getList()
    },
    filters: {
      srcImg(row) {
        return row.proPic.split(',')[0]
      }
    },
    methods: {
      // 失去焦点后再次获取焦点
      blur() {
        this.$nextTick(() => {
          this.$refs.input.focus()
        })
      },
      // 查询订单详情
      searchInput() {
        if (this.input3.length != 10) return
        this.$http.post(`/pro/selectPro`, null, {
          params: {
            id: this.input3
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.item = res.data.data
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      // 提现
      getCode() {

      },
      // 获取列表
      getList() {
        this.$http.post(`/pro/withdrawOrders`, null, {
          params: {
            current: this.page,
            size: this.limit,
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.commodityList = res.data.data.list
            this.yue = res.data.data.yue
            this.input3 = res.data.data.count
            this.accountTitle = res.data.data.name
            this.subBranch = res.data.data.kind
            // this.input3 ? this.accountState = true : this.accountState = false;
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      // 提现
      addCommd() {
        this.centerDialogVisible = true
        this.currentDialog = '提现'
        this.getCode()
      },
      // 核销商品
      handleEdit(index, row) {
        this.centerDialogVisible = true
        this.currentDialog = '提现'
        this.input3 = row.proUnique
        this.searchInput()
      },
      // 删除
      handleDelete(index, row) {
        this.$confirm('确认删除该商品?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post(`/pro/delete`, null, {
            params: {
              id: row.id
            }
          }).then(result => {
            if (result.data.code == 200) {
              this.$message.success(result.data.msg)
              this.getList()
            }
          })
        });
      },
      //每页大小改变
      handleSizeChange(newSize) {
        this.limit = newSize
        this.getList()
      },
      // 弹框关闭
      close() {
        this.comName = ''
        this.fileList = []
        this.currentItem = ''
        this.textarea = ''
        this.price = ''
        this.item = ''
      },
      // 当前页改变
      handleCurrentChange(newPage) {
        this.page = newPage
        this.getList()
      },
      // 提现确定
      submitMap() {
        if (this.money && this.input3 && this.accountTitle && this.subBranch) {
          if (this.money > this.yue) {
            this.$message.error('提现金额不能大于余额')
            return
          } else if (this.money == 0) {
            this.$message.error('提现金额不能是0')
            return
          }
          this.$http.post(`/pro/withdraw`, null, {
            params: {
              name: this.accountTitle,// 账号名称
              carKind: this.subBranch,//支行
              cardId: this.input3,
              money: this.money,
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              this.$message.success(res.data.msg)
              this.centerDialogVisible = false
              this.getList()
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          this.$message.error('请输入完整信息')
        }
      },
      // 上传图片成功
      handleAvatarSuccess(res, file, fileList) {
        console.log(fileList)
        this.fileList = fileList
      },
      // 删除图片
      handleRemove(file, fileList) {
        this.fileList = fileList
      },
      // 上传图片成功
      handleAvatarSuccess2(res, file, fileList) {
        console.log(fileList)
        this.fileList2 = fileList
      },
      // 删除图片
      handleRemove2(file, fileList) {
        this.fileList2 = fileList
      },
    }
  }
</script>

<style scoped lang="less">
  /deep/.input-placeholder input::-webkit-input-placeholder {
    color: #181818 !important;
  }

  .dialogItem {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .title {
      width: 100px;
    }
  }


  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>